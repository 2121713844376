<template>
  <CopyToBrowse></CopyToBrowse>
</template>

<script>
import CopyToBrowse from "@/components/copy_to/CopyToBrowse";
export default {
  name: "Browse",
  components: { CopyToBrowse }
};
</script>

<style scoped></style>
