<template>
  <LoadingBar v-if="loading"></LoadingBar>
  <Page v-else title="Browse Report Recipients">
    <v-form>
      <v-row>
        <v-col cols="8">
          <v-text-field v-model="search" label="Search"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-radio-group v-model="show">
            <v-row>
              <v-col>
                <v-radio label="Show Active" value="active"></v-radio>
              </v-col>
              <v-col>
                <v-radio label="Show All" value="all"></v-radio>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :headers="this.headers"
      :items="this.shownCopyTos"
      :search="search"
      item-key="id"
      no-data-text="You have no Report Recipients."
      no-results-text="No matching Report Recipients"
      :items-per-page="15"
      :footer-props="{
        itemsPerPageText: 'Rows count',
        pageText: 'Navigate Pages',
        itemsPerPageAllText: 'All'
      }"
    >
      <template v-slot:item.actions="{ item }">
        <span v-if="item.is_active">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-cancel</v-icon
              >
            </template>
            <span>Deactivate Report Recipient</span>
          </v-tooltip>
        </span>
        <span v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="toggleActivation(item)" v-bind="attrs" v-on="on"
                >mdi-check</v-icon
              >
            </template>
            <span>Activate Report Recipient</span>
          </v-tooltip>
        </span>
      </template>
      <template v-slot:item.setup="{ item }">
        <v-icon v-if="!item.o_user_id" color="orange"
          >mdi-dots-horizontal</v-icon
        >
        <v-icon v-if="!!item.o_user_id" color="green">mdi-check</v-icon>
        {{ !!item.o_user_id ? "Complete" : "Pending" }}
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-icon v-if="item.is_active" color="green">mdi-check</v-icon>
        {{ item.is_active ? "Active" : "Inactive" }}
      </template>
    </v-data-table>
    <v-btn color="primary" @click="invite">Invite New Report Recipient</v-btn>
  </Page>
</template>

<script>
import Page from "@/components/layout/Page";
import LoadingBar from "@/components/loaders/LoadingBar";
import { mapActions, mapState } from "vuex";
export default {
  name: "CopyToBrowse",
  components: { LoadingBar, Page },
  data() {
    return {
      loading: false,
      show: "active",
      search: "",
      headers: [
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Business Name", value: "business_name" },
        { text: "Email", value: "email" },
        { text: "Setup", value: "setup" },
        { text: "Status", value: "is_active" },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  computed: {
    ...mapState("copyTo", { copyTos: "items" }),
    shownCopyTos() {
      if (this.loading) return [];
      return this.show === "active"
        ? this.copyTos.filter(copyTo => copyTo.is_active)
        : this.copyTos;
    }
  },
  methods: {
    ...mapActions("copyTo", ["getCopyTos", "editCopyTo"]),
    toggleActivation(item) {
      this.loading = true;
      this.editCopyTo({
        uuid: item.uuid,
        is_active: !item.is_active
      }).then(() => {
        this.getCopyTos().finally(() => {
          this.loading = false;
        });
      });
    },
    invite() {
      this.$router.push("/copy-to/add");
    }
  },
  mounted() {
    this.loading = true;
    this.getCopyTos().finally(() => {
      this.loading = false;
    });
  }
};
</script>

<style scoped></style>
